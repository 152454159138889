<div class="cumulative">
  <div
    class="top"
    id="top"
    [style.background-image]="'url(' + background_path + ')'"
  >
  <div class="logo" *ngIf="menu.id == 2250"><br><br><br><br></div>
  <div class="logo" *ngIf="(menu.id==2491 || menu.id==2523)" style="display: flex; flex-direction: column; align-items: center; padding-bottom: 20px;">
      <img  *ngIf="menu.id==2491" src="assets/imgs/smokery/The_Smokery_Beach_bianchi-white.png"
        width="175"
        height="auto"
        class="l"
      />
      <img  *ngIf="menu.id==2523" src="assets/imgs/smokery/smokery-beach-msh-bianci.png"
        width="175"
        height="auto"
        class="l"
      />
      <div class="cls"></div>
  </div>
  <div class="logo" *ngIf="menu.id==2500 || menu.id==2499 || menu.id==2496" style="display: flex; flex-direction: column; align-items: center; padding-bottom: 20px;">
      <img src="assets/imgs/pasca/white_logo_png_pasca.png"
        width="175"
        height="auto"
        class="l"
      />
      <div class="cls"></div>
  </div>

    <div class="logo" *ngIf="menu.id != 2250 && (menu.id!=2491 && menu.id!=2523) && menu.id!=2500 && menu.id!=2499 && menu.id!=2496">
      <img *ngIf="menu.text_color == 'black'"
        src="assets/imgs/icons/logoblack.svg"
        width="100"
        height="auto"
        class="l"
      />
        <img *ngIf="menu.text_color == 'white'"
        src="assets/imgs/icons/logowhite.svg"
        width="100"
        height="auto"
        class="l"
      />

      <h6 *ngIf="menu.text_color == 'black'" style="color:black;">Welcome</h6>
      <h6 *ngIf="menu.text_color == 'white'" style="color:white;">Welcome</h6>

      <div class="cls"></div>
      <!-- <img src="assets/imgs/icons/theG.svg" class="log"> -->
    </div>
    <div class="buttons text-center">
      <h6 *ngIf="menu.text_color == 'black'" style="color:black;">{{ qrcodes_title || 'MENUS' }}</h6>
      <h6 *ngIf="menu.text_color == 'white'" style="color:white;">{{ qrcodes_title || 'MENUS' }}</h6>
      <!-- <div class="vertical-center"> -->
      <div *ngFor="let b of qrButtons">
        <a href="{{ b.value }}?back=true">{{ b.key }}</a>
      </div>
      </div>
    <!-- </div> -->
    <div class="social text-center">
      <div class="s">
        <a href="{{ getLink(survey) }}" *ngIf="survey"
          >CLICK HERE AND GIVE US YOUR OPINION</a
        >
      </div>
      <div class="icons">

        <a href="{{ getLink(instagram) }}" target="_blank" *ngIf="instagram && (menu.id==2491 || menu.id==2523)"
          ><img src="assets/imgs/icons/Insta.png"
        /></a>
        <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook && (menu.id==2491 || menu.id==2523)"
          ><img src="assets/imgs/icons/FB.png"
        /></a>

        <a href="{{ getLink(instagram) }}" target="_blank" *ngIf="instagram  && (menu.id!=2491 && menu.id!=2523)"
          ><img src="assets/imgs/inst1.jpg"
        /></a>
        <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook  && (menu.id!=2491 && menu.id!=2523)"
          ><img src="assets/imgs/face1.jpg"
        /></a>
        <a href="{{ getLink(website) }}" target="_blank" *ngIf="website"
          ><img src="assets/imgs/site1.jpg"
        /></a>
        <div *ngIf="(menu.id==2491 || menu.id==2523) || menu.id==2500 || menu.id==2499 || menu.id==2496">
          <p style="color: white;">powered by<a style="color: white; " href="https://touch-less.co"><u>touch-less.co</u></a></p>
        </div>
      </div>
    </div>
    <!-- <div class="swipe text-center" [routerLink]="'.'" [fragment]="'bottom'">
      <p>Swipe up for menu</p>
      <i class="fa fa-chevron-down"></i>
    </div> -->
  </div>
  <div *ngIf="file_path && (menu.id!=2491 && menu.id!=2523) && menu.id!=2500 && menu.id!=2499 && menu.id!=2496" class="bottom" id="bottom">
    <div class="f" [class.show_f]="show_f">
      <div class="row">
        <div class="col-6">
          <div class="b_home" [routerLink]="'.'" [fragment]="'top'">HOME</div>
        </div>
        <div class="col-6">
          <div class="c_side">
            <div class="logo" id="s_logo" (click)="show('s_logo', 'i_logo')">
              <img src="{{ logo_path }}" width="50" height="50" />
            </div>
            <div class="icons text-center" id="i_logo">
              <a
                href="{{ getLink(instagram) }}"
                target="_blank"
                *ngIf="instagram"
                ><img src="assets/imgs/inst1.jpg" width="25" height="auto"
              /></a>
              <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook"
                ><img src="assets/imgs/face1.jpg" width="25" height="auto"
              /></a>
              <a href="{{ getLink(website) }}" target="_blank" *ngIf="website"
                ><img src="assets/imgs/site1.jpg" width="25" height="auto"
              /></a>
              <a href="{{ getLink(survey) }}" *ngIf="survey"
                ><img src="assets/imgs/sur1.jpg" width="25" height="auto"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img
      *ngIf="isPdf != 'pdf'"
      src="{{ file_path }}"
      width="100%"
      height="auto"
    />
    <pdf-viewer
      *ngIf="isPdf == 'pdf'"
      [src]="file_path"
      [original-size]="true"
      [fit-to-page]="true"
      [render-text]="true"
      [autoresize]="true"
      (after-load-complete)="callBackFn($event)"
      style="display: block"
    >
    </pdf-viewer>
  </div>

  <!-- <div class="spin" id="spin">
        <img class="loader-spinner" src="{{logo_path}}" />
    </div> -->
</div>
